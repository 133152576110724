import { Navigate, Outlet } from "react-router-dom";
import { useLoggedIn } from "../StateStore/GlobalStore";

const Auth = () => {
  const user = useLoggedIn((state) => state.loggedIn);

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default Auth;
