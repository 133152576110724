import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./index.scss";
import Auth from "./Security/Auth";

const Login = lazy(() => import("./components/Login/Login"));
const Home = lazy(() => import("./components/Home/Home"));

function App() {
  return (
    <div className="App">
      <Suspense fallback="Loading...">
        <Routes>
          <Route path="/" element={<Login />} />

          <Route element={<Auth />}>
            <Route path="/home" element={<Home />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
