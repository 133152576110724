import { create } from "zustand";

// Registered user id when logged in
export const useUserId = create((set) => ({
  userId: "",
  setUserId: (newUser) => set({ userId: newUser }),
}));

// Logged in user
export const useLoggedIn = create((set) => ({
  loggedIn: null,
  setLoggedIn: (isLoggeIn) => set({ loggedIn: isLoggeIn }),
}));
